// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'qa',
  production: false,
  region: 'NA',
  appUrl: 'https://zpass.dev.zonarsystems.net',

  apiBase: {
    url: 'https://zonar-nonprod-qa.apigee.net'
  },

  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net/core/v1'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'qa',

  auth: {
    clientID: 'z8IuWt1adtjZtVOpaX9GoxMpYKldN0cY',
    domain: 'zonar-qa.auth0.com',
    audience: 'http://apiqa.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: 'dfcb6d74-46cd-4c71-8f74-42ae3b4bd157"',
    defaultZonarRole: 'c85e2462-47cf-483d-920e-8d04c061701b',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: '57878a2e-49d1-4e54-8a5d-989a78f8e8c9',
    clientToken: 'pub3ae6b4efaba3048aa70d308f5c3df1b5',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'qa'
  },

  i18n: {
    supportedLanguages: [
      'en',
      'en-US',
      'de-DE',
      'en-GB',
      'it',
      'fr-FR',
      'de',
      'es-ES'
    ],
    defaultLanguage: 'en-US'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
